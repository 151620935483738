import * as React from 'react'
import Loco from './Loco'

interface MyProps {
    status_options:any,
    tup:any,
    aspect:string,
    cb_parent:   (e: any, cb: (r: any) => void) => void    

}
type MyState = {
    is_loading:boolean,
    status_value:string
    status_lab:string,
    comments: Array<string>,
    comment_count: number,
    value_count:number
}
class SupplierQuotationAspect extends React.PureComponent<MyProps,MyState> {
    
    constructor(props: MyProps) {
        super(props)

        this.handle_comment_click = this.handle_comment_click.bind(this)
	this.handle_value_click	= this.handle_value_click.bind(this)
	this.state = {
	    is_loading:false,
	    comments:[],
	    comment_count:0,
	    value_count:0,
	    status_lab:'',
	    status_value:''
	}
    }    
    private labels: { [string: string]: string } 	= {
	'huidig' :  'volgens bewoners',
	'voorstel' : 'volgens leverancier',
    }
    render_cdropdown(){
	return (<div></div>)
    }
    handle_comment_click(e:any){
	console.log('handle comment-click')
	var lbl = this.labels[this.props.aspect]
	this.props.cb_parent({
	    cmd: 'quotations-comment-vote',
	    //tup:this.props.tup,
	    label: lbl,
	    attr_id: this.props.tup.id,
	    post_id:this.props.tup.post_id,
	    aspect: this.props.aspect,
	    top3:[]
	}, (r:any)=>{
	    if(r.hook === 'before_api_call'){
		this.setState({is_loading:true})
	    }else{
		//this.setState({comment: r.winner_txt, is_loading:false})
		console.log('set comments?')
		if(r.new_state){
		    this.setState({
			comments: r.new_state.map((x:any)=>x.value),
			comment_count: r.new_state.length,
			is_loading:false
		    })
		}
	    }
	})
	
    }
    handle_value_click(e:any){
	console.log('handle-value-click')
	var lbl = this.labels[this.props.aspect]
	this.props.cb_parent({
	    cmd: 'quotation-status-vote',
	    //tup:this.props.tup,
	    label: lbl,
	    attr_id: this.props.tup.id,
	    post_id:this.props.tup.post_id,
	    aspect: this.props.aspect,
	    acf_key_select: this.props.status_options.key,
	}, (r:any)=>{
	    if(r.hook === 'before_api_call'){
		this.setState({is_loading:true})
	    }else{
		console.log(r)
		this.update_choice_winner( r.winner );
	    }
	})
    }
    update_choice_winner(winner:string){
	console.log(` update winner ${winner}`)
	let slab = this.get_status_label(winner)

	console.log(` lab: ${slab} `)
	this.setState({
	    is_loading:false,
	    status_lab : slab,
	    status_value:winner
	})
	
    }
    handle_click_history(e:any,row:any,asp:string){
	console.log('history')

	this.props.cb_parent({
	    cmd: 'quotation-history',
	    attr_id: row.id,
	    post_id: row.post_id,
	    choices: this.props.status_options.choices,
	    aspect: asp
	},()=>{})
    }
    
    componentDidMount(){
	let tup = this.props.tup
	let asp = this.props.aspect
	let atup = this.props.tup[asp]
	if('comments' in atup){
	    let txt_array = atup.comments.map((x:any)=>x.value)
	    this.setState({
		comments: txt_array,
		comment_count: atup.comment_count
	    })
	}

	this.setState({
	    value_count: atup.status_count,
	    status_lab: this.get_status_label(atup.status),
	    status_value: atup.status
	})
    }
    get_status_label(val:string){
	let choices = this.props.status_options.choices
	if(val !== null){
	    return choices[val];
	}
    }
    render_comments(){
	return (<div className="text">
				{this.state.comments.map((txt:string,j:number)=> {
				    let k = `t-${j}`
				    return (<div key={k} className="part" >{txt}</div>)
				})}
		</div>)
    }
    render(){
	let tup = this.props.tup
	let aspect = this.props.aspect
	let has_edit_perm = (aspect === 'voorstel')
	let status_val = tup[aspect].status
	var label = this.labels[aspect]

			
	let status_lab = this.state.status_lab

	let status_count = 0
	return (
	    <div className="aspect panel">
		<div className="header">
		    <div className="label">{label}</div>
		    <span data-balloon="Toon historie"
			  data-balloon-pos="up" className="cf-icon-btn"
			  onClick={(e:any)=>this.handle_click_history(e,tup,aspect)}><i className="bb-icon-l bb-icon-list"/></span>
		</div>
		<div className="body">
		<div className="status-fields">
		    {has_edit_perm ? (
			<div className="winner read-write" onClick={this.handle_value_click}>
			<div className="label">
			    {status_lab ==='' ? Loco.tr('no_value_yet'):status_lab}
			    <div className="hulp-text">(klik hier om dit te wijzigen)</div>
			</div>
			<span className="cf-count-btn"
			      data-balloon="Keuze en details" data-balloon-pos="up">
			    {this.state.value_count}
			</span>
		    </div> ) : (
			<div className="readonly winner">
			    <div className="label">
				{status_lab ==='' ? Loco.tr('no_value_yet'):status_lab}
			    </div>
			    <span className="cf-count-btn"
			       data-balloon="Keuze en details" data-balloon-pos="up">
			      {this.state.value_count}
			    </span>
			</div>
		    )}
		    <div className="action-buttons">{this.render_cdropdown()}</div>
		    
		</div>
		<div className="remarks">
		{has_edit_perm ? (

		    <div className="text-with-btn" onClick={this.handle_comment_click}>
			{this.render_comments()}
	    		<span className="cf-count-btn"
			      data-balloon="Keuze en details" data-balloon-pos="up"> {this.state.comment_count}</span>
			</div>
		):(
		    <div className="text-readonly">
			{this.render_comments()}
	    		<span className="cf-count-btn"
			      data-balloon="Keuze en details" data-balloon-pos="up"> {this.state.comment_count}</span>

		 </div>
		)}

		</div>
		</div>
	    </div>

	)
    }
}

export default SupplierQuotationAspect
